.plns{
    display: flex;
    flex-direction: column;
    margin-top: 13px;
    .card{
        margin: 10px;
        margin-bottom: 4px;
        border: 2px solid rgb(175, 157, 157);
        border-radius: 40px;
        cursor: pointer;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add box shadow */
        padding: 20px; /* Add padding for content spacing */
        background-color: #ffffff; /* Add background color */
        transition: box-shadow 0.3s ease; /* Add transition effect */
    
        &:hover {
          box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15); /* Adjust box shadow on hover */
        }
            h4{
            text-align: center;
            border: 2px solid #199a8e;
            border-width: 7px;
            // width: 148px;
            background-color:#199a8e;
            color: #fff;
            width: 106px;
            margin: auto;
        }
        h3{
            text-align: center;
        }
        p{
            text-align: center;
            color: gray;
        }
        span{

              cursor: pointer;
              margin-bottom: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
        }
        Button{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
        }
    }
}