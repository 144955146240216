.homepage {
    display: flex;
    flex-direction: column;
    background: #e8f3f1;


.home_sub_container {
    display: flex;
    flex-direction: column;
}

// .loginContainer_carousel {
//     width: 33.3333%;
//     flex: 1 1 0%;
//     height: auto;
//     padding: 0px;
//     opacity: 1;
// }

/* Keep your existing styles */
.image-slider {
  display: flex;
  overflow: hidden;
  animation: moveLeftToRight 10s linear infinite;
}

.slide {
  flex-shrink: 0;
  width: 100%;
  max-width: 30%;
  margin-right: 10px;
}

.slide img {
  height: 120px;
  max-width: 100px;
  max-height: 120px;
  margin: auto;
  border-radius: 5px;
}

/* Revised styles for item class */
.item .customImg img {
  width: 300px!important;
  height: 300px!important;
  max-width: 238px!important;
  max-height: 138px!important;

}

@keyframes moveLeftToRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}


.btnms {
  margin: 15px;
}

.button_container {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  margin-top: 21px;
}

.button_container button {
  border-radius: 10px;
  background: #199a8e;
  border: 1px solid #c8d4d2;
  transition: box-shadow 0.3s ease; /* Add transition effect */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 93px;
  .imgs{
    fill: #eaf2f1;
  }
}

.button_container button:hover {
  box-shadow: 0px 0px 10px rgba(80, 216, 240, 0.3); /* Change box shadow on hover */
}

.button_container button img {
  height: 45%;
  width: 25%;
  color: #199a8e;
  margin-bottom: 10px;
}

/* Target the SVG element specifically */
.button_container button img svg {
  fill: #eaf2f1; /* Change the fill color for the SVG */
}

.button_container button span {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}


.container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 79px; 
    position: relative; 
    margin-top: 2px;
    height: 100px;


  }
  .cardbtn{
    display: grid;
  grid-gap: -0.5rem;
  grid-template-columns: 1fr 1fr ;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  background: #f1e9e9;
  }

  
  .card {
    width: 161px;
    height: 216px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add box shadow */
    padding: 20px;
    animation: slideIn 1s linear forwards; 
    opacity: 0; 
    overflow: hidden; 
    position: relative; 
    margin-left: 18px;
    margin: 15px;

    &:hover {
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15); /* Adjust box shadow on hover */
    }
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(100%); 
    }
    to {
      opacity: 1;
      transform: translateX(0); 
    }
  }
  
  .card img {
    width: 56px;
    height: auto; 
    border-radius: 8px; 
    margin-bottom: 10px;
  }
  
  .card h3 {
    font-size: 12px; 
    margin-bottom: 5px; 
  }
  
  .card p {
    font-size: 10px; 
    color: #555; 
    margin-bottom: 14px; 
  }
  
  .card button {
    background-color: #199a8e; 
    color: #fff; 
    border: none; 
    padding: 6px 12px; 
    font-size: 12px; 
      border-radius: 4px; 
    cursor: pointer; 
    transition: background-color 0.3s ease;
  }
  
  .ms-article-section {
    padding: 80px 0;
    background: #f6f6f6;
}
.ms-article-box {
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0 10px 30px #eaf2f1;
    transition: 0.3s all linear;
}
.ms-article-box:hover {
  box-shadow: 0 10px 30px #1c2020;
}
.ms-article-box .ms-featured-img img {
    transition: 0.3s all linear;
}
.ms-article-box .ms-featured-img:before {
    background: var(--ms-primary-color);
    position: absolute;
    content: '';
    width: 100%;
    height: 0%;
    left: 0px;
    top: 0px;
    opacity: 0.8;
    z-index: 1;
    transition: all 500ms ease;
}
.ms-article-box:hover .ms-featured-img:before {
    height: 100%;
}
.ms-article-box:hover .ms-featured-img img {
    transform: scale(1.1);
    cursor: pointer;
}
.ms-article-content {
    padding: 30px;
}
.ms-category-meta {
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 99;
}
.ms-category-meta a {
  border: 2px solid #989494;
    background: #199a8e;
    font-size: 14px;
    color: #fff;
    padding: 7px 15px;
    border-radius: 10px;
}
.ms-author-meta img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
}
.ms-author-meta p {
    color: #2d2929;
    font-size: 15px;
    font-weight: 600;
}
.ms-description p {
    font-size: 15px;
    color: var(--ms-third-color);
}
.ms-description a {
    font-size: 16px;
}
.ms-description a:before, .ms-description a:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -2px;
    height: 2px;
    width: 0;
    transition: width 0s ease;
}
.ms-description a:before {
    left: 0;
}
.ms-description a:after {
    right: 0;
    background: var(--ms-primary-color);
    transition: width .5s ease;
}
.ms-description a:hover {
    color: var(--ms-primary-color);
}
.ms-description a:hover:before {
    width: 100%;
    background: var(--ms-primary-color);
    transition: width .5s ease;
}
.ms-description a:hover:after {
    width: 100%;
    background: transparent;
}
  
  .card-body{
    background: none;
  }

// @media (max-width:550px) {

//   /*==================== 02. Homepage CSS ====================*/
//   /*----- 02.a. Banner Section -----*/
//   .ms-home-banner {
//       padding: 80px 10px;
//       padding-bottom: 0px !important;
//   }
//   /*----- 02.b. Stand Apart Section -----*/
//   .ms-apart-section {
//       padding: 60px 10px;
//   }
//   /*----- 02.c. Connect Section -----*/
//   .ms-connect-section {
//       padding: 60px 10px;
//   }
  
//   /*----- 02.d. Top Articles Section -----*/
//   .ms-article-section {
//       padding: 60px 10px 30px;
//   }
//   .ms-article-box {
//       margin-bottom: 30px;
//   }


// }


}

