.loginContainer {
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  position: relative; /* Add this line */
  z-index: 1; /* Add this line */
  overflow: hidden; /* Prevent content from overflowing the container */


  
  &__option {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    padding: 10px 0;
    justify-items: center;
    align-items: center;
    background-color: $light-color;
    border: 1px solid $primary-color;
    border-radius: 24px;
    width: 100%;
    position: relative;
    
    &__disabled{
      pointer-events: none !important;
      border: 1px solid #d9d9d9 ;
    }
    
    &__img {
      width: 24px;
      height: 24px;
    }
    &__select input:focus {
      outline: none;
    }
    &__list {
      ul {
        width: 90% !important;
        margin: auto;
        left: 5%;
        border-radius: 8px;
        top: 3rem;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
    }
    &__select {
      input {
        border: none;
        background-color: transparent;
        width: 100%;
        font-style: normal;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        color: $primary-color;
      }
      ::placeholder {
        color: $primary-color;
        opacity: 0.4;
      }
      :-ms-input-placeholder {
        color: $primary-color;
        opacity: 0.4;
      }
      ::-ms-input-placeholder {
        color: $primary-color;
        opacity: 0.4;
      }
    }

    
    
    



  }

  &_login {
    box-shadow: none !important;
    height: 100vh;
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-direction: column;
  }
  &_carousel {
    &_card {
      &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        max-height: 50vh;
      }
      &__image {
        max-height: 316px;
        max-width: 316px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &__text {
        margin-top: 10px !important;
        font-weight: 700;
        font-size: 18px;
        line-height: 19px;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        color: $primary-color;
      }
    }
  }
  &_button {
    border-radius: 32px !important;
    background-color: $primary-color !important;
    width: 320px;
    padding: 10px 0px !important;
  }

  &_number {
    margin-top: 10px;
    padding: 10px 0;
    background-color: $light-color;
    border: 1px solid $primary-color;
    border-radius: 24px;
  }

  &_number:focus {
    .loginContainer_icon {
      span {
        color: $primary-color;
      }
    }
  }

  &_icon {
    display: flex;
    align-items: center;
    > span {
      color: #a1a8b0;
    }
  }

  &_phone-input {
    padding-left: 10px !important;
    > div:before {
      transform: none !important;
      border: none !important;
    }
    > div:after {
      border: none !important;
    }
    > div {
      input {
        color: $primary-color !important;
      }
    }

    .Mui-focused {
      transform: none !important;
    }
  }

  &_checkbox {
    color: $primary-color !important;
    &_text {
      font-size: 13px !important;
      a {
        color: $primary-color;
      }
    }
  }

  &_country {
    display: flex;
    width: fit-content;
    margin-top: 2px;
    > div {
      color: $primary-color !important;
    }
    > div:before {
      border: none !important;
    }
  }

  &_otp {
    box-shadow: none !important;

    &__box {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__input {
      display: flex;
      justify-content: center;
      width: 100% !important;
      // margin: auto !important;
      padding: 9px 0 !important;
      background-color: $light-color;
      border: 1px solid $primary-color !important;
      border-radius: 24px;
      padding-left: 10px !important;
      &__box {
        width: 82% !important;
        > div:before {
          transform: none !important;
          border: none !important;
        }
        > div:after {
          border: none !important;
        }
        > div {
          input {
            color: $primary-color !important;
          }
        }
        .Mui-focused {
          transform: none !important;
        }

        input {
          letter-spacing: 4px;
          text-align: center;
        }
      }
    }

    &_countdown {
      color: $white-color;
      margin-left: 5px;
    }

    &_button-container {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    &_title {
      font-weight: 700 !important;
      font-size: 24px !important;
      line-height: 135% !important;
      color: $text-black;
    }

    &_text {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 150% !important;
      color: #a1a8b0;
      span {
        color: $text-black;
      }
    }
  }
}

.validation-error {
  color: $error-color !important;
  text-align: center !important;
}

.loginContainer_login .paging-item {
  svg {
    height: 10px;
    width: 10px;
  }
}

.loginContainer_login .active {
  svg {
    fill: $primary-color;
  }
}

.loginContainer_login {
  .slider-control-bottomcenter {
    position: relative;
    top: 30px;
  }
}

.login-text {
  font-family: "Inter";
  display: flex;
  justify-content: center;
  margin: 20px 0 10px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #888888;
}

.profile_form {
  &__blood {
    margin: 2rem 0 0 1.2rem;
    > div {
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      gap: 0.4rem;
    }

    &__label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #858585;
    }

    input {
      position: absolute;
      // -webkit-appearance: none;
      height: inherit;
      width: inherit;
      cursor: pointer;
    }

    &_circle {
      position: relative;
      z-index: 2;
      display: flex;
      margin: 1rem 0 0 0;
      align-items: center;
      justify-content: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      cursor: pointer;
      color: #000000;
      // padding: 1rem;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      border: 0.5px solid #ff6868;

      &_selected {
        background-color: #ff6868;
        color: #fff;
      }
    }
  }
}

.profileLoginButton {
  padding-top: 2rem;

  @media (max-width: 420px) {
    padding-top: 1rem;
  }
}
.whtsp_switch{ 
display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  gap: 0rem;
  margin: 1.6rem auto;
  // margin-top: 124px;
  .label_with_icon{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
  }
svg{
    margin-top: -7px;
    padding-left: 4px;
    margin-bottom: -6px;
    color: green;
  }
  .label_text{
    font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 15px;
        color: #199a8e !important;
  }
}
.no_registration {
  display: flex; /* Use Flexbox to arrange items horizontally */
  align-items: center; /* Center items vertically */
  justify-content: center;
    span {
   margin-left: 5px;
   text-decoration: underline;
   color: #2096fb;
   font-size: 15px;
   font-weight: 500;
   cursor: pointer;
 }
 
}
// .family-photo-background{
//   background:  url('../../src/static/images/22.png');
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index:-1;

// }
.doctor-logo {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 100px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  z-index: 22; /* Ensure the logo is on top of other elements */
  background: transparent;
  object-fit: contain; /* Add this line */
  background-color: transparent; /* Add this line */
  border-radius: 50%;
}
.doctor-log {
  position: absolute;
  top: 43rem;
  left: 1rem;
  width: 100%;
  height: auto;
  z-index: 2;
  color: #160c0c;
  display: flex;
  flex-direction: row; /* Add this line to stack the items vertically */
  gap: 12px; /* Adjust the gap between items as needed */
  font-weight: 400;
  font-size: 17px;
}

.ft {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ft p {
  margin: 0;
  padding: 0;
  letter-spacing: 0;
  line-height: 1.125rem;
  color: #fff;
}

.foms{
  position: fixed;
  right: 24px;
  width: 100%;
}

.form-container {
  background-color: #fff;
  position: relative;
  z-index: 21;
  max-height: 100%; /* Set the maximum height to 100% of the parent container */
  overflow-y: auto; /* Add a scrollbar if the content exceeds the container height */
  border: 2px solid transparent;
  border-radius: 21px;
  right: -14px;
  box-shadow: 8px 4px 4px 0px rgba(0, 0, 0, 0.25);


}
@media (max-width: 767px) {
  .form-container {
    right: -27px; /* Reset the right property for mobile view */
    max-height: 80vh; /* Increase the maximum height to 80% of the viewport height */
    align-items: center;
    top: 20px;
  }
  
}
@media(max-width: 1295px){
  .doctor-log{
    display: flex;
    flex-direction: column;
  }
}
.gtr{
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 767px) {
  .gtr{
    margin-left: -32px;
  }
}
.gr{
  position: absolute;
  top: 33rem;
  left: 1rem;
  width: 100%;
  height: auto;
  color: #fff;
  font-size: 19px;

}
@media(max-width: 1295px){
  .gr{
    font-size: 16px;
  }
}
.background-image-container {
  position: relative;
}

.family-photo-background {
  // filter: blur(10px); /* Adjust the blur value as needed */
  mask-image: radial-gradient(circle at 2% -12%, transparent 2%, black 24%)
}
