.loginC{
  // height: 100vh;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;

}
.avtar{
    display: flex;
    align-items: center;
    justify-content: center;
    .rounded-circle {
      height: 105px;
      width: 103px;
    }

}

.btnm {
  margin: 26px;
  margin-top: -2rem;
  margin-bottom: 5rem;
}

.bt {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  gap: 1rem;
}

button {
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #c8d4d2;
  transition: box-shadow 0.3s ease;

}

button:hover {
  box-shadow: 0px 0px 10px rgba(15, 79, 92, 0.3);
}

.tags {
  margin: -76px;
  margin-right: -104px;
  margin-left: 4px;
  margin-top: -101px;
}

.tittles {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: -64px;
}