.caution {
  width: 90%;
  margin: auto;
  &__container {
    display: flex;
    gap: 0.4rem;
  }

  &__error {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ff6868;
  }

  &__effects {
    &__list {
        list-style: outside;
        li{
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 30px;
        }
    }
  }
}
