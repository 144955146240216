.patientRecordsContainer {
  grid-template-columns: 2fr 2fr 1fr !important;
  padding: 1.4rem 1rem !important;
  width: 100% !important;
  margin-left: 1.5rem !important;

  &_date {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #50555c;
  }

  &_time {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #199a8e;
  }
}

.patientRecordsAppointment{
  grid-template-columns: 1fr 2fr !important;

 &_image__container{
    width: 70px !important;
    height: 70px !important;
 }

}