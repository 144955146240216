.ReactSwipeButton {
  float: left;
  width: 90%;
  margin: auto;
  left: 5%;
  height: 56px;
  position: relative;
  display: flex;
    align-items: center;
    justify-content: center;
}
.ReactSwipeButton,
.ReactSwipeButton * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}

.ReactSwipeButtonContainer {
  // position: fixed;
  position: absolute;
  bottom: 30px;
  width: 100%;
  height: 50px;
}

.rsbContainer {
  float: left;
  width: 360px;
  max-width: 100%;
  height: 100%;
  background: linear-gradient(269.98deg, #0a887c 1.15%, #1fcfbf 99.04%);
  border-radius: 50px;
  position: relative;
  /* box-shadow: inset 1px 1px 4px rgba(0,0,0,0.1); */
  overflow: hidden;
  

@media screen and (max-width:360px) {
  .rsbContainer{
    width:100%
  }
  
}
}
.rsbContainerUnlocked {
  width: 50% !important;
  margin-left: 25%;
  transition: 0.5s;
  cursor: default;
  >div{
    background:transparent;
  }
}
.rsbContainerUnlocked .rsbcSlider {
  left: 100% ;
  cursor: default;
  pointer-events: none;
}
.rsbContainerUnlocked .rsbcSliderArrow {
  transition: 0.5s;
  margin-right: -60px;
}
.rsbContainerUnlocked .rsbcSliderCircle {
  transition: 0.5s;
  margin-right: -60px;
}
.rsbcSlider {
  float: left;
  width: 100%;
  position: relative;
  height: 56px;
  top: 0;
  left: 50px;
  right: 50px;
  margin-left: -100%;
  background: #199a8e;
  border-radius: 25px;
  z-index: 100;
  /* box-shadow: 1px 1px 5px rgba(0,0,0,0.3); */
  cursor: pointer;
}
.rsbcSliderText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 50px;
  text-align: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  letter-spacing: 2px; 
  color: #fff;
  font-size: 13px;
}
.rsbcSliderArrow {
  position: absolute;
  right: 5px;
  top: 7px;
  height: 40px;
  width: 40px;
  z-index: 10;
}
.rsbcSliderCircle {
  position: absolute;
  right: 0;
  background: #51ad7e;
  /* border: 5px solid #157946; */
  top: 0;
  height: 50px;
  width: 50px;
  border-radius: 100%;
}
.rsbcText {
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  letter-spacing: 2px;
  font-size: 13px;
  color: #ffffff;
}
