
.overall-review-container {
    display: flex;
    align-items: center;
    justify-content: center;
    // gap: 1rem;
  }
  
  .avg-rating{
    font-size: 30px;
    font-weight: 600;
  }
  .review-count {
    font-size: 20px;
    font-weight: 500;
    margin-left: 42px;
    color: gray;
  }
  
  .rating-container {
    width: 200px; /* Adjust the width as needed */
    text-align: center;
  }
  
  .rating {
    width: 100%;
  }
.patientexp {
    display: grid;
    grid-template-columns:  1fr;
    grid-gap: -1.5rem;
    margin: 36px;
  }
  
  .feedback-card {
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }
  
  .respond-container {
    display: flex;
    align-items: center;
  }
  .rspnd {
    display: inline-block;
    padding: 10px 20px;
    background-color:#199a8e;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }
  .reviews-icon {
    width: 36px; /* Adjust the width as needed */
    height: 36px; /* Adjust the height as needed */
    margin-left: 0.5rem; /* Add some spacing between the text and icon */
    filter: invert(103%) sepia(24%) saturate(2391%) hue-rotate(43deg) brightness(75%) contrast(87%);

  }