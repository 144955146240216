.header {
  position: fixed;
  max-width: 480px;
  top: 0px;
  z-index: 4;
  width: 100%;
  max-width: 100vw;
}

.headerContainer {
  position: sticky;

  &__box {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 90%;
    margin: 0.3rem auto;
  }

  &__dropDown {
    position: absolute;
    top: 3.5rem;
    background-color: #FFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    padding: 0 0.5rem;
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      align-items: center;
      width: 100%;
      li {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        width: inherit;
        font-size: 12px;
        line-height: 1px;
        color: #101623;
        padding: 0.4rem 0;
      }
    }
  }

  .profileName {
    flex-grow: 1;
  }

  .avatarContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.4rem;
  }

  &__avatar {
    padding: 0 !important;
  }

  &__bellIcon {
    width: 30px;
    margin-left: 20px;
    cursor: pointer;
  }

  &__searchIcon {
    width: 30px;
    cursor: pointer;
  }
}

.rounded-circle {
  height: 50px;
  width: 50px;
}
.dropdown-separator {
  border-bottom: 1px solid #ccc;
  margin: 5px 0;
}
