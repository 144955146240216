.mobileTimerPicker {
  max-width: 120px;
  label {
    font-size: 12px;
    top: unset !important;
    bottom: 1.3rem;
    left: 1rem;
  }
  .MuiInputLabel-shrink {
    bottom: 0.8rem;
    left: 0.5rem;
  }
  > div {
    max-height: 30px;
  }

  &__label{
    position: absolute;
    font-size: 10px;
    background-color: white;
    top: -0.5rem;
    right: 1rem;
    text-transform: capitalize;
  }

  &__switch {
    > span {
      height: 20px;
      margin-top: 2px;
      margin-bottom: 2px;
      >span{
        span{
            height: 16px !important;
            width: 16px !important;
        }
      }
    }
  }
}
