.footer{
    // position: fixed;
    bottom:0;
    width: 100%;
    background-color: transparent;
    .footerLogo{
        max-width: 30px;
        vertical-align: middle;
    }
    &-link a{
        padding-right:5px;
        color:#333;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
}