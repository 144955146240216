.prescription__list__container {
  grid-template-columns: 1fr 1.8fr 1.5fr !important;
}

.prescriptionContainer {
  // margin-bottom: 44rem;
  // margin-top: 7rem;
  &__heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    width: 90%;
    line-height: 15px;
    color: #50555c;
    // text-align: center;
    margin: 1rem auto auto auto;
    padding-top: 2rem;
    margin-left: 2rem;

    @media (max-width: 370px) {
      font-size: 12.5px;
    }
  }

  &__dates {
    display: flex;
    margin-top: 2rem;
    // justify-content: space-around;
    gap: 25px;
    margin-left: 2rem;
  }

  &__container {
    margin-top: 0 !important;
  }

  &__search {
    width: 73%;
    border: 1px solid #f4f4f4;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    margin: 1.4rem auto 0 auto;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    margin-left: 2rem;
    margin-bottom: 1rem;

    input {
      outline: none;
      width: 100%;
      border: none;
      filter: none;
      // margin-left: 1rem;
    }
  }

  &__date {
    background-color: transparent !important;
    margin-top: 20px;
  }
}

.prescriptionpage {
  min-height: 100vh;
  overflow-x: auto;

  &__container {
    padding: 1.4rem 1rem 0.5rem 1rem;
    border-radius: 12px;
    width: 85%;
    margin: auto;
    margin-bottom: 1rem !important;
    border: 1px solid #cdcdcd;
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #50555c;
    width: 80%;
    margin: 1.2rem auto;
  }

  &__medication {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__reminder {
      &__switch {
        flex-direction: row-reverse;
        margin-left: unset !important;
        margin-right: unset !important;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  &__button {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    &__disable {
      background-color: #d9d9d9 !important;
      pointer-events: none;
      color: black !important;
      // opacity: 0.65;
    }

    &__false {
      background-color: $primary-color !important;
      color: black !important;
    }

    &__save_button {
      color: white;
      background-color: $primary-color;
    }

    // &__save_button:hover {
    //   background-color: $primary-color;
    // }
    &__add_button {
      background-color: $primary-color;
      color: #fff;
    }
  }

  &__lastSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  &__swipe__disable {
    background: #d9d9d9 !important ;
    pointer-events: none;
    h1 {
      color: #000 !important;
    }
  }

  &__swipe_btn {
    background: #199a8e;
    border-radius: 32px;
    justify-content: space-between;
    padding: 0.2rem 1.8rem 0.2rem 0.8rem;
    margin: 1rem auto;
    max-width: 320px;
    &__container {
      position: unset !important;
      margin: 1rem 0;
    }

    &__image {
      img {
        width: 40px;
        height: 40px;
      }
    }

    &__text {
      color: #ffffff;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 15px;
    }

    &__button {
      > div {
        background: $primary-color !important;
      }
    }
  }
}

.prescription {
  &__container {
    // background-color: #e8f3f1;
    border: 1px solid #e8f3f1;
    &__middleSection {
      &__status {
        justify-content: start;
      }
    }
    &__lastsection {
      height: 80%;
      align-items: end;
    }
  }

  &_error {
    color: red;
    font-size: 10px;
  }

  &_recent-heading {
    font-size: 14px;
    text-align: start;
    margin-left: 0.5rem;
  }

  &_recent-medication {
    width: 90%;
    margin: 1rem auto 1rem auto;
    // border: 1px solid #cdcdcd;
    // border-radius: 12px;

    &__scroll {
      display: flex;
      justify-content: start;
      align-items: center;
      max-width: 100%;
      padding: 0 0.4rem;
      overflow-x: scroll;
      margin: 1rem 0 1.5rem 0;
      gap: 0.5rem;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
    }

    &_button {
      background: #e8f3f1;
      border: 0.5px solid #199a8e;
      border-radius: 8px;
      width: fit-content;
      font-family: "Inter";
      min-width: fit-content;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      padding: 0.4rem 1rem;
      color: #000;
      cursor: pointer;
      &_selected {
        color: #fff !important;
        background: #199a8e !important;
      }
    }
  }

  &_editor {
    padding: 20px 20px;
    > div {
      box-shadow: none;
      border-radius: 10px;
      border: 1px solid #cdcdcd;
    }
  }

  &_toolbar {
    // width: 74%;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    grid-gap: 0.5rem;
    // margin-left: 2rem;
    // margin: 0.5rem 0 0.5rem 0.5rem;

    &_button {
      all: unset;
      width: 100%;
      display: flex;
      justify-content: center;
      // align-items: center;
      background-color: #e8f3f1;
      cursor: pointer;
      border-left: 1px solid $primary-color;
      border-bottom: 1px solid $primary-color;
      height: 50px;

      &:focus {
        outline: revert;
      }

      span {
        min-width: fit-content;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        padding: 0.6rem 0;
        line-height: 15px;
        text-align: center;
      }

      &_selected {
        background-color: $primary-color;
        span {
          font-weight: 600;
          color: #fff;
        }
      }
    }
  }
}

.prescription__input__reports {
  width: max-content;
  background: #d9d9d9;
  border-radius: 50px;
  > div {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;
  }
}

.prescriptionMedicianBox {
  &__drug {
    width: 60%;
  }
  &__type {
    width: 30%;
  }
  &__split {
    display: flex;
    justify-content: space-between;
  }
}

.prescriptionPrompt {
  width: 90%;
  margin: auto;
  &__container {
    position: relative;
    background: #ff6868;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 1.3rem 0;
    width: 100%;
    margin-bottom: 1rem;

    &__close {
      top: 4px !important;
      right: 4px !important;
      svg {
        height: 1rem;
        color: #ffffff;
      }
    }

    &__box {
      display: flex;
      width: 90%;
      margin: auto;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      &__msg {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
      }

      &__icon {
        svg {
          height: 35px;
          color: #ffffff;
        }
      }
    }
  }
}

.prescripitionLoader {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin: 2rem 1rem 4rem 1rem;
  align-items: center;
  justify-content: center;

  h3 {
    font-weight: 600;
    font-size: 22px;
    margin: 0;
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 1rem;
    text-align: center;
  }

  span {
    color: $primary-color !important;
    height: 60px !important;
    width: 60px !important;

    circle {
      stroke-width: 3 !important;
    }
  }
}

.prescripitionLoading {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin: 2rem 1rem 4rem 1rem;
  align-items: center;
  justify-content: center;

  h3 {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }
  button {
    border: 0;
    background-color: transparent;
    position: absolute;
    right: 25px;
    top: 20px;
  }

  .forms {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
  .medi1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    &_container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    img {
      height: 100%;
      width: 100px;
      padding: 0.5rem;
    }

    p{
      text-align: center;
      font-size: 12px;
      width: 80%;
      margin: auto;
    }
  }

  .medi2 {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 15px;
      width: 20px;
    }

    .selected {
      border: 1px solid rgb(221, 169, 150);
    }
  }
}

.prescriptionReportAppointment {
  grid-template-columns: 1.5fr 2fr 1fr !important;
}

.prescription__pdf {
  max-height: 500px;
  overflow-y: scroll;
  position: relative;
  &__container {
    display: flex;
    position: sticky;
    bottom: 1rem;
    width: 100%;
  }
  .rpv-core__text-layer {
    display: none;
  }
  .rpv-core__inner-page {
    width: 100% !important;
    > div {
      width: 100% !important;
    }
  }
  .rpv-core__canvas-layer {
    width: 100% !important;
    canvas {
      border-radius: 5px;
      width: 100% !important;
    }
  }

  &__button {
    max-width: 100px;
    padding: 0.3rem 1rem;
    margin: auto;
    text-align: center;
    border-radius: 6px;
    background-color: #199a8e;
    opacity: 0.9;
    cursor: pointer;
    margin-bottom: 0.5rem !important;
    display: block;
    color: white;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
  }
}

.query_container {
  margin: 15px;

  .complaint_container {
    width: 47%;
    margin: 2rem auto auto auto;
    &_heading {
      font-size: 18px;
      text-align: center;
    }
  }

  .PrinterSvg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 15px;
  }

  &_provisional{
    margin: 1rem 0;

    h3{
      margin: 0;
      margin-bottom: 1rem !important;
    }
    button{
      border: 1px solid #e8f3f1;
      border-radius: 22px;
      background-color: #199a8e;
      padding: 1rem;
      width: 150px;
      cursor: pointer;

      img{
        width: 30px;
        height: 30px;
      }
    }

    &_text{
      font-size: 13.5px;
      font-weight: bold;
      letter-spacing: 0.05rem;
      color: #FFF;
      margin-bottom: 0.5rem;
    }
  }

  .printsub {
    margin: 1rem 0;
    .print-template-bar {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 500;
      color: #7f7f7f;

      h2 {
        font-size: 14px;
        flex: 0 0 auto;
        font-weight: 600;
      }

      select{
        cursor: pointer;
        padding:0.5rem;
        font-size: 14px;
        font-weight: bold;
        border-radius: 8px;
        margin-left: 0.2rem;
        background-color: #e8f3f1;
        text-align: center;
        border: none;
        flex-shrink: 0;
        outline: none;
        color: #000;  
        // -webkit-appearance: none;

        option{
          background-color: #FFF;
        }
        &::-webkit-scrollbar {
          width: 10px;
          height: 4px;
          border-radius: 1rem;
        }
        &::-webkit-scrollbar-track {
          background-color: #FFF;
        }

        &::-webkit-scrollbar-thumb {
          background-color: inherit;
        }
      
      }

      span {
        font-size: 13px;
      }

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }

      &-print {
        cursor: pointer;
      }
    }
  }

  .new_query_container{
    &_disable{
      background-color: #7f7f7f;
      pointer-events: none;
      opacity: 0.7;
    }
  }
}

.borderAdd {
  border: solid 2px #000;
}
.date-banner{
  display: flex;
  align-items: center;
  height: 29px;
  font-style: normal;
  // justify-content: space-between;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  background-color: #a1e4d8;
}
.day{
  margin-left: 30px;
  color: #199a8e;
  margin-right: 5px;
}
