.ver {
    display: flex;
    flex-direction: column;
    margin-top: 86px;
  }
  
  .ver .card {
    margin: 10px;
    margin-bottom: 4px;
    border: 2px solid rgb(175, 157, 157);
    border-radius: 40px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #ffffff;
    transition: box-shadow 0.3s ease;
  }
  
  .ver .card:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
  
  .ver h2 {
    text-align: center;
    margin-top: -88px;
    margin-left: 49px;
    color: #199a8e;
  }
  
  .ver no {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e7ccee;
    border-width: 10px;
    width: 95px;
    margin-left: 120px;
    background-color: #e7cce5;
    border-radius: 10px;
    color: #aa162f;
  }
  
  .ver p {
    text-align: center;
    color: gray;
  }