

.cheif-back-btn {
    display: flex;
        gap: 10px;
        align-items: center;
    button {
        border: 0;
        background-color: transparent;

        img {
            max-width: 30px;
            width: 100%;
        }
    }
}
.cheif_complaints{
    min-height: 40vh;
    // width: 75%;
    // margin-left: 2rem;
    .save-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #199a8e;
        cursor: pointer;
    }
}

.prescription{
 &_days{
    margin-top: 1.3rem;
    select{
        cursor: pointer;
        padding:0.5rem;
        font-size: 14px;
        font-weight: bold;
        border-radius: 8px;
        // margin-left: 0.2rem;
        margin-top: 0.5rem;
        max-width: 80px;
        background-color: #e8f3f1;
        text-align: center;
        border: none;
        flex-shrink: 0;
        outline: none;
        color: #000;  
        // -webkit-appearance: none;
    
        option{
          background-color: #FFF;
        }
        &::-webkit-scrollbar {
          width: 10px;
          height: 4px;
          border-radius: 1rem;
        }
        &::-webkit-scrollbar-track {
          background-color: #FFF;
        }
    
        &::-webkit-scrollbar-thumb {
          background-color: inherit;
        }
    }
 }
 &_weeks{
    display: flex;
    gap: 0.8rem;
    margin-top: 0.7rem;
    flex-wrap: wrap;
    div{
        display: flex;
        align-items: center;
        gap: 0.3rem;

        input{
            accent-color: $primary-color;
            cursor: pointer;
        }
        label{
            color: rgba(0, 0, 0, 0.6);
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.4375em;
            letter-spacing: 0.00938em;
            padding: 0;
        }
    }
}
}