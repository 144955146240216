.navContainer {
   max-width: $small-screen;
    position: fixed;
    bottom: 0;
    background-color: white;
    margin:0 auto;     
    left: 0px;
    right: 0px;
    z-index: 4;

    >div{
      div{
        display: flex;
        justify-content: space-around !important;
      }
      >span{
        background-color: transparent !important;
      }
    }
    
    &__button {
      img{
        color: $primary-color;
      }
      >span{
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #898A8D !important;
      }
      .Mui-selected{
        >span{
          path{
            fill: #f50057;
            stroke: #f50057;
          }
        }
     }
    }
  }

.DetailsPageContainer{
  background-color: $white-color;
}

.detailsContainer{
  background-color: $white-color;
}

.Mui-selected {
  >span{
    >svg{
      path{
        fill: $primary-color;
        stroke: $primary-color;
      }
    }
  }
}

.navContainerTabs{
  >div{
    padding: 0px !important;
  }
}