.treament_pro{
    border: 1px solid #cdcdcd;
    border-radius: 60px;
    box-shadow: 0px 0px 10px #cdcdcd;
    background-color: #fff;
    padding: 20px;
    width: 100%;
    // max-width: 480px;
    box-sizing: border-box;
    margin-right: 20px;
    margin-top: 20px;
    position: relative;

    .selection_finding{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .savely-btn {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .prescriptionpage__medication {
        flex-direction: column;
        align-items: flex-start;
        label {
            font-size: 12px;
        }
        .select-date {
            margin: 15px 0;
        }
    }
    
    
}

.treatment_pro_btn {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    margin-top: 0;
    justify-content: center;
    
    button {
        border: 0;
        background-color: transparent;
        position: absolute;
        right: 25px;
        top: 20px;

        img {
            max-width: 30px;
            width: 100%;
        }
    }
}
  

.days{
    width: 100px;

    MenuItem{
        padding: 8px; 
        max-height: 50%;
    }
}

.text-nowrap {
    white-space: nowrap;
}

.check_days {
    label {
        white-space: nowrap;
    }
}