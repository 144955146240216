.dosage {
  display: flex;
  align-items: center;
  justify-content: center;
  &__container {
    width: 90vw;
    min-height: 59vh;
    background-color: #ffffff;
    border-radius: 10px;
    position: relative;

    @media (min-width: 480px) {
      max-width: 420px;
      margin: auto;
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 4;
    cursor: pointer;
  }

  &__heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin: 2rem 0;
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;

    &__button {
      display: flex;
      justify-content: center;
      margin: 2rem 0;
      width: 100%;
    }

    &__textarea {
      display: flex;
      justify-content: center;
      width: 100%;

      > textarea {
        width: 100%;
        background: rgba(0, 0, 0, 0.06);
        border: 1px solid rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        padding: 10px;
      }

      > textarea:focus {
        outline: 0;
      }
    }

    &__feilds {
      // display: flex;
      // justify-content: space-around;
      display: grid;
      grid-template-columns: 1fr 2fr;
      justify-items: end;
      grid-gap: 2rem;
      width: 100%;
      margin-right: 2rem;

      &__title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: #898a8d;
      }

      &__box {
        display: flex;
        gap: 1rem;

        &__prescription {
          max-width: 90px;
        }
      }
    }
  }
}

.plusminus {
  &__container {
    background: #e8f3f1;
    border-radius: 5px;
    width: 90px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &__inc,
  &__dec {
    color: #199a8e !important;
    border-radius: 10px !important;
    border: 2px solid #199a8e !important;
    padding: 0 !important;
    min-width: unset !important;

    > span {
      > svg {
        width: 1.3rem !important;
        height: 1.3rem !important;
      }
    }
  }

  &__count {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: 1.5rem;
    line-height: 15px;
    text-align: center;
    background: #e8f3f1;
    color: #000000;
    padding: 0;
    outline: none;
    border: none;

    // chrome , safari, Edge , Opera
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // FireFox
    -moz-appearance: textfield;
  }
}
