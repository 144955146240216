.addServices {
  min-height: 100vh;
  position: relative;
  margin-top: 1rem;
  &_container {
    padding: 0 1.5rem;
  }

  &__table {
    padding-top: 1rem;
    // width: 72%;
    // margin-left: 13rem;
  }

  &__new-button {
    display: flex;
    justify-content: end;
    margin-top: 1rem;
    button {
      padding: 10px 5px;
      width: 30%;
    }
  }
  &__new-buttons {
  
    button {
      height: 19px;  /* Or whatever height you prefer */
      width: 25%;
      padding: 10px 5px;  
    }
  }


  &__error {
    font-size: 14px;
    text-align: center;
  }

  &__heading {
    color: #50555c;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    margin-top: 1rem;
    align-items: start;

    p {
      margin-top: 0;
      margin-left: 12rem;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 6rem;
    gap: 1rem;
    right: 0;
    button {
      border-radius: 8px;
      color: white !important;
      width: 70%;
    }
  }

  &__raiseOrder {
    position: relative;
    height: 30vh;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: #fff;

    &_text {
      font-family: "Inter";
      font-size: 14px;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      width: 100%;
    }

    &_loader {
      position: absolute;
      height: 30vh;
      display: flex;
      border-radius: 8px;
      justify-content: center;
      align-items: center;
      width: 100%;
      z-index: 4;
      background-color: rgba(0, 0, 0, 0.5);
      // background-color: pink;
    }

    &_buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;

      &_online_button {
        background-color: #5d5fef;
      }

      &_cash_button {
        background-color: #c4377f;
      }

      button {
        border-radius: 8px;
        color: white !important;
        width: 35%;
      }
    }

    &_message {
      font-family: "Inter";
      font-size: 15px;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      width: 100%;
    }

    &_button_message_button {
      border-radius: 8px;
      color: white !important;
      width: 35%;
    }

    &_bottomText {
      font-family: "Inter";
      font-size: 12px;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      width: 100%;
    }
  }

  &__cancelOrder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem;
    gap: 1rem;
    align-items: center;
    min-height: 25vh;

    &_text {
      font-family: "Inter";
      font-size: 15px;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      width: 100%;
    }

    &_buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;
      button {
        border-radius: 8px;
        color: white !important;
        width: 35%;
      }
    }
  }
}

.addServicesModal {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 50vh !important;

  &__submit {
    min-height: 30vh !important;
    justify-content: start;
    > div {
      min-height: 20vh;
      margin: 0 2rem;
      text-align: center;
      div {
        margin-top: 1rem;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }
      button {
        position: absolute;
        bottom: 2rem;
      }
    }
  }

  &__header {
    margin: 1.5rem 1.4rem 1rem 1.4rem;
    border: 1px solid #bdbdbd;
    border-radius: 10px;

    > div {
      overflow-y: hidden;
      overflow-x: scroll;
      margin: 0.5rem 1rem;

      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }
  }

  &__chip {
    color: #199a8e !important;
    background-color: white !important;
    border: 1px solid #199a8e !important;

    &_active {
      color: #fff !important;
      background-color: #199a8e !important;
      // border: 1px solid #199a8e !important;
    }
  }

  &__form {
    margin: 0.5rem 1.8rem 1rem 1.8rem;

    form {
      margin: auto 1rem;
    }

    &_service {
      width: 90%;
    }
    &_input {
      -webkit-appearance: none;
      outline: none;
      padding: 0.5rem 1rem;
      border: 1px solid #e7e7e7;
      border-radius: 8px;

      &::placeholder {
        color: #d9d9d9;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 16px */
      }
    }

    &_amount {
      margin: 1rem 0;
      display: flex;
      justify-content: space-between;
    }

    &_fee {
      width: 30%;
      input {
        padding: 0.5rem 0.4rem;
      }
    }

    &_discount {
      width: 30%;
      input {
        padding: 0.5rem 0.4rem;
      }
    }

    &_curreny {
      width: fit-content;
      padding: 0.5rem 1rem;
      border: 1px solid #e7e7e7;
      border-radius: 8px;
      color: #000;
      font-family: "Inter";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      max-height: 16px;
      line-height: 100%;
    }

    &_applicable {
      display: flex;
      justify-content: center;
      gap: 2rem;
    }

    input[type="checkbox"] {
      accent-color: #199a8e;
    }

    &_isgst {
      display: flex;
      align-items: center;
      span {
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        max-width: 80px;
      }
    }

    &_total {
      display: flex;
      justify-content: space-around;
      margin-top: 1.5rem;
      padding-top: 1rem;
      border-top: 1px solid #e7e7e7;
      span {
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        max-width: 110px;
        width: 100%;
      }

      span:nth-child(2) {
        text-align: end;
        font-size: 15px;
        font-weight: 800;
      }
    }

    &_gst {
      width: 30%;

      &_disabled {
        background-color: #e7e7e7;
        color: #fff !important;
        pointer-events: none;
      }
    }

    &__button {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      gap: 2rem;
      &_save {
        color: #fff;
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        border-radius: 8px;
        padding: 0.3rem 1rem;
        width: 25%;
        background: #199a8e;
      }

      &_cancel {
        color: #199a8e;
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        border-radius: 8px;
        padding: 0.3rem 1rem;
        width: 25%;
        background: #fff;
        border: 1px solid #199a8e;
      }
    }

    &_error {
      margin: 0.2rem 0 0 0.2rem;
      font-family: "Inter";
      font-size: 10px;
      color: red;
      font-style: normal;
      font-weight: 600;
      max-width: 80px;
    }
  }
}

.raiseOrder {
  min-height: 100vh;
  position: relative;

  &__reload {
    position: absolute;
    top: 4rem;
    right: 2rem;
  }

  &__order {
    padding: 2rem 1rem;

    &_heading {
      text-align: center;
      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      width: 100%;
    }
    &__container {
      display: flex;
      flex-direction: column;
      margin-top: 1.5rem;

      &_details {
        border-top: 1px solid $primary-color;
        border-bottom: 1px solid $primary-color;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0.6rem 0;
        justify-content: space-around;
        span {
          font-family: "Inter";
          font-size: 16px;
          color: #000;
          text-align: center;
          font-style: normal;
          font-weight: 600;
          width: 100%;
        }
      }

      &_list {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0.6rem 0;
        width: 100%;

        &_discount {
          color: red !important;
        }
        span {
          font-family: "Inter";
          font-size: 14px;
          color: #000;
          text-align: center;
          font-style: normal;
          font-weight: 400;
          width: 100%;
        }
      }

      // &_list:nth-child(even) {
      //   background-color: $primary-color;
      //   span {
      //     color: white;
      //   }
      // }

      &_total {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0.6rem 0;
        border-top: 1px solid black;
        margin-top: 0.5rem;
        border-bottom: 1px solid $primary-color;

        span {
          font-family: "Inter";
          font-size: 14px;
          color: #000;
          text-align: center;
          font-style: normal;
          font-weight: 700;
          width: 100%;
        }
      }
    }

    &_confirm {
      font-family: "Inter";
      font-size: 14px;
      color: #000;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      margin: 1rem 0 1rem 0;
      width: 100%;
    }

    &_status {
      text-align: center;
      margin-top: 1rem;
      font-family: "Inter";
      font-size: 14px;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      margin: 1rem 0 1rem 0;
      color: $primary-color;
      width: 100%;
      span{
        color: #000;
        font-size: 13px;
      }
    }

    &_buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      button {
        border-radius: 8px;
        color: white !important;
        width: 80%;
      }
    }
  }

  &_popup {
    min-height: 30vh;
    position: relative;
    padding: 2rem 2rem;

    &_payment {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &_heading {
      font-family: "Inter";
      font-size: 16px;
      color: #000;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      width: 100%;
    }

    &_loader {
      left: 0;
      top: 0;
      height: 57.5vh !important;
    }

    &_text {
      font-family: "Inter";
      font-size: 14px;
      color: #000;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      width: 100%;
    }

    &_form {
      display: flex;
      justify-content: center;
      padding: 1rem 0;
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }

      &_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      }
      &_phone {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        span {
          border: 1px solid #e7e7e7;
          border-radius: 8px;
          padding: 0.4rem 0.5rem;
          font-family: "Inter";
          font-size: 14px;
          color: #000;
          text-align: center;
          font-style: normal;
          font-weight: 400;
          width: 100%;
        }
      }

      &_email {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: end;
      }

      &_switch {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin-top: 0.5rem;

        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.4rem;
          label {
            margin: 0;
          }
        }
      }

      &_button {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        width: 50%;
        button {
          padding: 0.5rem 0.8rem;
          width: 100%;
        }
      }
    }
  }
}

.paymentStatus__popup {
  height: 30vh;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  &_cancel {
    height: 20vh !important;
  }

  &_text {
    font-family: "Inter";
    font-size: 15px;
    color: #000;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    width: 100%;
  }

  &_button {
    width: 100%;
    button {
      margin: 0.5rem auto auto auto;
      width: 40%;
    }
  }
}
