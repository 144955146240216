.clinicalFindings {
  max-width: 400px;
  padding: 1rem;
  margin: auto 0.5rem;
  min-height: max-content;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;

  .MuiSlider-markLabel {
    background: transparent !important;
    box-shadow: none !important;
  }

  &_bubbles {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    &_disable {
      background: #898a8d !important;
    }

    &_button {
      &_button {
        font-size: 10px;
        text-align: center;
        width: fit-content;
        text-transform: capitalize !important;
      }
    }
  }

  &_container {
    margin-top: 2rem;
  }
  &_heading {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  &_slider_heading {
    font-size: 14px;
    font-weight: 500;
  }
}

.duration_slider_name {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.duration__slider {
  &__bottom {
  }

  &__manual {
    margin: 0.5rem auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    p {
      margin: 0.3rem 0 0.5rem 0;
      font-size: 11px;
      font-weight: 600;
    }

    input{
      border: 0;
      width: 90%;
      font-weight: 700;
      font-size: 14px;
      border-bottom: 0.1rem solid #898a8d ;
      padding-left: 1rem;
      outline: none;
    }
  }

  &__submit{
    &_button{
      margin: 0.6rem 0 0 0;
      border-radius: 6px;
      padding: 8px 20px;
      width: fit-content;
      align-self: end;
    }
  }
}
