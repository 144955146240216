.duration {
  &__container {
    min-height: unset !important;
    max-height: fit-content !important;
  }

  &__heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin: 2rem 0;
  }

  &__currentValue {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
    text-align: center;
    margin-bottom: 1rem;
  }

  &__slider {
    width: 90%;
    margin: auto;

    > span {
      > span {
        background: linear-gradient(269.98deg, #0a887c 1.15%, #1fcfbf 99.04%);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        padding: 2px 0;
      }
      :last-child {
        // background: white;
        // color: white !important;
        // // border: 4px solid $primary-color;
      }
    }
  }
}
