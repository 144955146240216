
.Refferel {
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  //  align-items: center;
   padding: 20px  ;

   input, textarea{
    outline: none;
   }
   .selection{
    margin-top: 2px;

   }

   .level{
  margin: 10px;
  padding: 5px;
  display: flexbox;
  justify-content: space-between;
  align-items: center;
   }

   .form-group  {
    margin-bottom: 1rem;
    width: 100%;
   }

   label {
    font-size: 12px;
    width: 100%;
   }

   .form-control {
    border: 0;
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    font-size: 14px;

   }
   
   input[type=number]::-webkit-inner-spin-button, 
   input[type=number]::-webkit-outer-spin-button { 
       -webkit-appearance: none;
       -moz-appearance: none;
       appearance: none;
       margin: 0; 
   }

   .dr-text {
    width: 100px
   }
  .saveli{
    display: flex;
    align-items: center;
    justify-content: center;
    &_disable{
      pointer-events: none;
    }
    button{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #199a8e;
      height: 3vh;
      width: 116px;
      font-weight: bold;
      color: #FFF;
      font-size: 12px;
      padding: 1.2rem 0;
      border: 0px solid #ddd;
      border-radius: 10px;
    }
  }
  .no-with-code {
    position: relative;
  span {
    position: absolute;
    top: 10px;
    font-size: 14px;
    }
    input {
      padding-left: 50px;
    }
  }

  &_list{
    position: absolute;
    top: 1.5rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #FFF;
    z-index: 2;
    padding:0.7rem 40px;
    max-height: 180px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
      height: 4px;
      border-radius: 1rem;
    }
    &::-webkit-scrollbar-track {
      background-color: #FFF;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e8f3f1;
    }
    li{
      padding: 0.4rem 0;
      list-style-type: none;
      cursor: pointer;
      font-size: 14px;
      // border-bottom: 1px solid #000;
      &:hover{
        text-decoration: underline;
      }
    }

  }
    
  }
  