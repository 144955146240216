.doctrens {
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin: 12px;
    margin-top: 10px;
  }
  
  .tile {
    background-color: #fff;
    // padding: 1rem;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);;
    margin-top: 12px;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .tile img {
    width: 37px;
    height: 34px;
    float: right;
    margin-top: 1rem;
    margin-right: 1rem;
  }
  .updated-on {
    display: flex;
    flex-direction: column;
    color: gray;
    align-items: flex-end; /* Align text to the right */
    margin-right: 1rem;
  }