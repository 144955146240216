
.patient_note {
    border: 1px solid #ccc;
    padding: 20px 0;
    //  padding: 25px 0;
    font-family: Arial, sans-serif;
    height: 100vh;
    position: relative;
    box-sizing: border-box;
  }
  
  .patient_note {
    h2 {
      font-size: 25px;
      margin: 0;
      position: relative;
      display: flex;
      padding: 5px 10px;
      font-weight: 500;
      button {
        flex: 0 0 auto;
        flex-shrink: 0;
        background-color: #fff;
        padding: 0;
        border: none;
        cursor: pointer;
        img {
          width: 24px;
          margin-right: 9px;
          margin-top: -1px;
        }
      }
    }
    .para{
      display: flex;
      align-items: center;
      border-top: solid 2px #dee;
      border-bottom: solid 2px #dee;
      padding: 1px 15px;
      p{
        color: darkgoldenrod;
      }
    }
    .pnh-inner {
      display: flex;
      align-items: center;
      border-top: solid 2px #dee;
      border-bottom: solid 2px #dee;
      padding: 1px 15px;
      img{
        height: 40px;
        width: 50px;
      }
      p {
        margin: 0;
        font-size: 19px;
        font-weight: 600;
        color: #7f7f7f;
      }
      span {
        font-size: 12px;
        font-weight: 600;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .write_notes {
    position: relative;
    padding: 20px;
    border-top: solid 2px #dee;
    border-bottom: solid 2px #dee;

    input {
      width: 100%;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      border-radius: 30px;
      padding: 15px 40px 15px 15px;
      box-sizing: border-box;
    }

    button {
      position: absolute;
      right: 36px;
      top: 30px;
      padding: 0;
      background: transparent;
      outline: none;
      border: 0;

      img {
        width: 24px;
      }
    }
  }
  
  .patient_cl {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(86vh - 212px);
    overflow-y: scroll;

    ul {
      list-style: none;
      height: 100%;
      margin: 0;
      padding: 0px 12px;
      li {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 10px;
        .note-time {
          font-size: 12px;
          font-weight: 500;
          color: #5c5858;
          display: block;
          margin-bottom: 5px;
        }
        .notes-outer {
          position: relative;
          max-width: 300px;
          padding: 20px;
          padding-right: 30px;
          background: #eee;
          border-radius: 10px;

          .btn {
            position: absolute;
            right: 6px;
            top: 20px;
            background: #000;
            outline: none;
            border: 0;
            width: 16px;
            height: 16px;
            border-radius: 20px;
            text-align: center;
            img {
              width: 14px;
              color: #fff;
            }
          }
        }
      }
    }
  }