
.dito{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // border: 1px solid #199a8e;
    margin-top: 1rem;
    border-radius: 12px;
    background: #e8f3f1;
    // caret-color: transparent;
}
.quadrant-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 63vw;
    place-self: center;
  }
  
  .quadrant {
    padding: 5px;
    position: relative;
  }
  
  .quadrant-1 {
    // border-left: 1px solid #199a8e;
    border-bottom: 1px solid #199a8e;
  }
  
  .quadrant-2 {
    border-bottom: 1px solid #199a8e;
  }
  
//   .quadrant-3 {
//     border-left: 1px solid #199a8e;
//   }
.quadrant-2 {
    border-left: 1px solid #199a8e;
  }
  .quadrant-4 {
    border-left: 1px solid #199a8e;
  }
  
  textarea {
    width: 100%;
    height: 100%;
    resize: none;
    border: none;
    outline: none;
    font-size: 16px;
  }
  .quadrant {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .teeth-container {
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  
  .tooth {
    width: 30px;
    height: 30px;
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .tooth.selected {
    background-color: #199a8e;
    color: white;
  }


  .details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quadrants {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.quadrant {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.quadrant input {
  width: 150px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.plus-sign {
  font-weight: bold;
  margin-right: 5px;
  font-size: 20px;
}

.ditos {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 1rem;
  border-radius: 12px;
  background: #f8f9f9;
}

.treatments {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin-bottom: 20px;
  width: 100%;
}

.treatments-text {
  width: 21%; /* Adjust this value as needed */
  margin-right: 20px;
  padding: 10px;
  background-color: #bab5b5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-left: 11rem;
}

.quadrants-grid {
  width:26%; /* Adjust this value as needed */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: -5px;
}
.quadrants {
  padding: 5px;
  position: relative;
}

.quadrants-1 {
  border-bottom: 1px solid #199a8e;
}

.quadrants-2 {
  border-bottom: 1px solid #199a8e;
  border-left: 1px solid #199a8e;
}

// .quadrants-3 {
//   border-left: 1px solid #199a8e;
// }

.quadrants-4 {
  border-left: 1px solid #199a8e;
}

.quadrants {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.teeths-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.tooths {
  width: 30px;
  height: 30px;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid #ccc;
  cursor: pointer;
}