.autocompLeteLoader{
    right: 0;
    top:50%;
    transform: translateY(-50%);

    >img{
        width: 15px;
    }
}
.autoCompleteList{
    cursor: pointer;
    
}
.textContent{
    min-height: 100px;
    >div{
        display: flex;
        gap: 0.8rem;
        flex-wrap: wrap;
    }
}
.autocompleteSubmit{
    top:auto;
    right:0;
}
.autoSuggesionUl{
    max-height:150px;
    overflow: auto;
    z-index: 12;
}