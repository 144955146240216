.home__carousel__image {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    border-radius: 20px;
  }
}

.home__container {
  &__carousel {
    &__heading {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 100%;
      margin-left: 30px;
    }
  }
}
