.loginCo  {
    background-color: #ffffff;
    position: relative;
    z-index: 1;
    max-width: 480px;
    margin: auto;
    background: #e8f3f1;
    background-repeat: repeat-y;

  }
  
  .loginCo .wrapper-background {
    background: url("./../static/images/Doctor_Portal_Full_Bkg.jpg");
    background-repeat: repeat-y;
    // min-height: 100vh;
    // height: 100vh;
  }

