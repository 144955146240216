.appointment__date {
  &__card {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    background-color:  #a1e4d8;
  }

  &__text {
    display: flex;
    align-items: center;
    height: 29px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-left: 1rem;
    > span {
      color: $primary-color;
    }
  }
  @media (max-width: 990px) {
    &__text {
        margin-left: inherit; /* Increase the margin-left for screens 990px or less */
    }
}


  &__record {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: #199a8e;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
    border-radius: 12px;
    padding: 8px 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #ffffff;
  }

  &__calendar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    // gap: 2rem;
    margin: 1.6rem auto;

    .MuiFormControlLabel-label {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
    }

    > label {
      flex-direction: row-reverse !important;
      width: 60%;
    }

    input {
      padding: 10px 10px;
      font-size: 14px;
    }

    fieldset {
      display: none;
    }

    &__date {
      // width: 218px;
      height: 44px;
      align-items: center;
      label {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.6) !important;
        margin-top: 10px;
      }
      img {
        // width: 35px;
        // height: 32px;
      }
    }
  }
}

.appointmentpage {
  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #50555c;
    width: 80%;
    margin: 1.2rem auto;
  }

  &__navigation {
    justify-content: space-between;
    // width: 72%;
    margin: auto;

    &__list {
      margin-top: 40px;
      margin-left: -3rem;
      &__button {
        justify-content: space-between;
        width: 72%;
        margin: auto;
        &__image {
          margin: 10px 0;
          img {
            max-width: 43px;
            max-height: 43px;
          }
        }

        &__text {
          font-weight: 600 !important;
          font-size: 19px !important;
          line-height: 19px !important;

          color: #101623;
        }
      }
    }
  }
}

.Appointment__date {
  // width: 93%;
  display: flex;
  align-items: center;
  height: 29px;
  font-style: normal;
  justify-content: space-between;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  background-color: #a1e4d8;
  // margin-left: 32px;

  span {
    margin-left: 30px;
    color: $primary-color;
    margin-right: 5px;
  }

  img {
    margin-right: 30px;
  }
}

.appointment__container {
  // display: grid !important;
  // grid-template-columns: 1fr 1.2fr 1.5fr;
  gap: 10px;
  justify-content: space-between;
  // margin: 10px auto !important;
  background: #e8f3f1;
  border: 1px solid #e8f3f1;
  border-radius: 11.1321px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.14);
  min-height: 95px;
  width: 100%;

  &__status__button {
    max-width: 100px;
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    outline: none;
    border: none;
    padding: 8px 10px !important;
    line-height: 12px;
    cursor: pointer;
  }

  &__status__button:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &__middleSection {
    padding: 0px !important;
    max-width: fit-content !important;

    &__gender {
      color: #adadad;
    }

    &__status {
      display: flex;
      width: 100%;
      padding: 2px 0;
      height: 24px;
      align-items: center;
      justify-content: center;
      gap: 4px;
      background: #e8f3f1;
      border-radius: 2.22642px;

      &__text {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #50555c;
      }
    }
    &__box {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 0.5rem;
    }
    &__error-name {
      &__style {
        text-align: start;
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 22px;
      }
    }
    &__error-gender {
      &__style {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: #adadad;
        margin: 0 0.2rem;
      }
    }
    &__error-age {
      &__style {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        margin: 0 0.2rem;
        // margin-left: 18px;
      }
    }
  }

  &__lastsection {
    padding: 0px !important;
    max-width: fit-content !important;
    &__status {
      display: flex;
      justify-content: space-evenly;
    }

    &__time {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 0.5rem;
      margin-bottom: 20px;
    }

    &__button {
      div {
        img {
          width: 35px;
        }
      }
    }
  }

  &__avatar {
    &__container {
      width: 54.05px;
      height: 54.05px;
      color: #adadad;
    }
  }
}

.newappointment__container {
  &__calender {
    // width: 48%;
    margin: 34px auto;

    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 15px;
      color: #50555c;
      // margin-left: 0.5rem;
      margin-top: 1rem;
    }

    &__date {
      display: flex;
      gap: 25px;
      margin-top: 2rem;

      input {
        padding: 10px 10px;
        // background-color: #E8F3F1;
        // border-radius: 5px;
        // font-size: 14px;
      }

      fieldset {
        display: none;
      }
    }

    &__datePicker {
      display: flex;
      background-color: #e8f3f1;
      border-radius: 14px;
      width: 210px;
      img {
        padding-right: 10px;
      }
      fieldset {
        display: none;
      }
    }
  }
}

.appointmentpage {
  height: 115vh;
  margin-top: 0rem;
}

.button_token {
  background-color: #eaa235 !important;
}

.appointment__screen__exit {
  font-weight: 600;
  font-size: 18px;
  margin: 1rem 0;
  color: red;
  text-align: center;
  &__buttons {
    button {
      padding: 10px 8px;
      width: 30%;
    }
    display: flex;
    gap: 1rem;
  }
}

.appointment__date__switch{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  gap: 2rem;
  margin: 1.6rem auto;
  // margin-top: 37px;
  .MuiFormControlLabel-label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
  }
  .label{
    font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000 !important;
  }
  
}

.appointmentpage__navigation__next {
  background-color:1px solid blue;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 58px;
  margin-bottom: 30px; 
}