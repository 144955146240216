@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

$small-screen: 480px;
$medium-screen: 768px;
$large-screen: 1025px;
$primary-color: #199a8e;
$white-color: #ffffff;
$light-color: #f9fafb;
$gray-color: #898a8d;
$text-black: #101623;
$pending-color: #ff6868;
$error-color: #b00020;
$primary-font: "Inter", sans-serif;

iframe {
  display: none !important;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.app-screen {
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  // max-width: 480px;
  margin: auto;
}

.wrapper-background {
  background: url("./../static/images/Doctor_Portal_Full_Bkg.jpg");
  background-repeat: repeat-y;
  // min-height: 100vh;
  // height: 100vh;
}

.icon-glow {
  color: $primary-color !important;
}

body {
  font-family: $primary-font;
  margin: 0;
  letter-spacing: 0.01em;
  font-size: 16px;
  height: 100vh;

  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}
.overflow-hidden {
  overflow: hidden;
}
#root {
  min-height: 100%;
  height: 100%;
}
.bodyBg {
  background-color: $white-color;
  // background: url('./../static/images/Doctor_Portal_Full_Bkg.jpg') no-repeat 100% 0;
  // background-size: cover;
  // @media only screen and (max-width: 600px) {
  //     background-image: url('./../static/images/doctor_portal_mobile_Bkg.png');
  // }
}

.errorcomponent {
  color: $error-color !important;
}

.Arrow--right {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #199a8e;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.32);
  border-radius: 50px;
}

.navigation__tab {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  // h1 {
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 26px;
  //   line-height: 29px;
  // }

  &__text {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    // margin-left: -7rem;
    
  }
}

.static_disable {
  background-color: #d9d9d9 !important;
  pointer-events: none !important;
  color: black !important;
}

.static_button {
  all: unset;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  padding: 15px 10px;
  background: #199a8e;
  border-radius: 32px;
  color: #ffffff;
  width: 40%;
  display: flex;
  justify-content: center;
}

.mySchedule_calendar {
  width: 22% !important;
  justify-content: space-between;
  border: 1px solid #000;
  padding: 0.4rem 0;
  background-color: transparent !important;
  margin-top: 1rem;
}

.error{
  font-size: 11px;
  color: red;
}
.iframe-call{
  position: unset !important;
  iframe{
    display: block !important;
  }
}

.gtp{
  margin-right: -280px;
  margin-bottom: -21px;
  color: #8b9fb3;
  font-weight: 600;
  cursor: pointer;
}
.whtsp_switch2{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  gap: 0rem;
  margin: 1.6rem auto;
  margin-top: -8px;
  margin-left: -53px;
  cursor: pointer;
.label_with_whtsp{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  svg{
    margin-top: -7px;
    padding-left: 4px;
    margin-bottom: -6px;
    color: green;
  }
.label_txt{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #199a8e !important;

}
}


}
.newbuton{
  width: 30%;
}
// .btnm {
//   margin: 15px;
// }

// .btn {
//   display: grid;
//   grid-gap: 0.5rem;
//   grid-template-columns: 1fr;
//   overflow: hidden;
//   width: 100%;
//   box-sizing: border-box;
//   margin-top: 21px;
// }

// button {
//   border-radius: 10px;
//   background: #ffffff;
//   border: 1px solid #c8d4d2;
//   transition: box-shadow 0.3s ease; /* Add transition effect */
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 117px;
//   cursor: pointer;
// }

// button:hover {
//   box-shadow: 0px 0px 10px rgba(15, 79, 92, 0.3); /* Change box shadow on hover */
// }

.btns{
  margin-left: 61px;
  margin-top: 20px;
  button{
    margin-right: 58px
  }
}

// .custom-avatar {
//   width: 50px;
//   height: 50px;
//   border-radius: 50%;
// }

// .custom-avatar svg {
//   margin-left: -164px
// }
.loginContaine{
  height: 186vh;
  display: flex;
  flex-direction: column
}
.loginCont{
  height: 110vh;
  display: flex;
  flex-direction: column

}


.ms-meta{
  position: absolute;
  // bottom: 30px;
  // left: 30px;
  // z-index: 9999 !important;

}
.ms-meta a{
  border: 2px solid #989494;
  background: #199a8e;
  font-size: 14px;
  color: #fff;
  padding: 7px 15px;
  border-radius: 10px;

}
.pnh-upr{
  display: flex;
  align-items: center;
  border-bottom: 2px solid #dee;
  border-top: 2px solid #dee;
  padding: 1px 15px;
  img{
    height: 40px;
    width: 50px;
  }
  p{
    color: #7f7f7f;
    font-size: 19px;
    font-weight: 600;
    margin: 0;
  }
  span{ 
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px;
  }

}

.time-period-heading {
  background-color: rgb(224, 224, 224);
  color: rgb(33, 33, 33);
  padding: 8px;
  margin-bottom: 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.time-slot-btn {
  font-size: 0.85rem !important;
  color: #199a8e;
  border: 1px solid #199a8e;
  border-radius: 4px;
  transition: all 0.2s linear;
  margin: 13px;
}

.time-slot-btn:disabled {
  color: #9e9e9e;
  border: 1px solid #9e9e9e;
  background-color: #eeeeee;
  margin: 13px;
}

.time-slot-btn:hover {
  background-color: #199a8e;
  color: white;
}

.time-slot-btn:disabled:hover {
  color: #9e9e9e;
  background-color: #eeeeee;
}
.custom-form input {
  height: 2.5rem;
  /* width: 50%; */
  padding: 0 4px;
  border: 2px solid #b3c6e7;
  border-radius: 0;
}
.calendar-container {
  max-width: 300px; /* Adjust this value as needed */
}

.narrow-calendar-input {
  width: 100%;
}

.patient_note {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
}

.para h2 {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.patient_cl {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.date-group {
  margin-bottom: 20px;
}

.date-header {
  text-align: center;
  margin: 20px 0;
  position: relative;
}

.date-header span {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9em;
  color: #666;
}

.message-list {
  display: flex;
  flex-direction: column;
}

.message {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  max-width: 70%;
}

.message.patient {
  align-self: flex-start;
}

.message.doctor {
  align-self: flex-end;
}

.message-content {
  padding: 15px;
  border-radius: 10px;
  word-break: break-word;
  margin: 10px;
}

.message.patient .message-content {
  background-color: #f1f8e9;
}

.message.doctor .message-content {
  background-color: #e1f5fe;
}

.message-time {
  font-size: 0.8em;
  color: #888;
  margin-top: 2px;
  align-self: flex-end;
}

.write_notes {
  display: flex;
  margin-top: 20px;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #eee;
}

.write_notes input {
  flex-grow: 1;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.write_notes button {
  background: none;
  border: none;
  cursor: pointer;
}
.unread-count {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: #199a8e;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  font-weight: bold;
}
.appointment__caseid__container {
  margin: 1rem 0;
  padding: 0 1rem;
}

.appointment__caseid__content {
  display: flex;
  justify-content: space-between;
  width: 72%;
  margin: auto
}

.case-id {
  display: flex;
  align-items: center;
}

.case-id span {
  font-weight: bold;
  margin-right: 0.5rem;
}

.case-id p {
  margin: 0;
}

.doctor-dropdown {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.doctor-dropdown span {
  font-weight: bold;
  white-space: nowrap;
}

.doctor-dropdown .MuiFormControl-root {
  width: 200px; /* Fixed width for the dropdown */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .appointment__caseid__content {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .doctor-dropdown {
    margin-top: 1rem;
    width: 100%;
  }
  
  .doctor-dropdown .MuiFormControl-root {
    width: 100%;
  }
}


@import "./variables";
@import "./bootstrap.display";
@import "./bootstrap.align";
@import "./utils";
@import "./gradientbutton";
@import "./helper";
@import "./tables";
@import "./invoice";
@import "./footer";
@import "./autocomplete";
@import "./detailsTab";
@import "./header";
@import "./login";
@import "./appointment";
@import "./home";
@import "./nextButton";
@import "./prescription";
@import "../OverlayManager/PrescriptionDosage/index.scss";
@import "../OverlayManager/PrescriptionSlider/index.scss";
@import "./caution";
@import "./patientRecords";
@import "../OverlayManager/ClinicalFindings/index.scss";
@import "./schedule.scss";
@import "./addServices";
@import "./PatientNotes.scss";
@import "./PrescriptionNotes.scss";
@import "./Treatment_Procedure.scss";
@import "./Refered.scss";
@import"./homepage.scss";
@import "./blog.scss";
@import "./Doctorbutton.scss";
@import "./PlanSubcrip.scss";
@import "./Verifyac.scss";
@import "./Checkout.scss";
@import "./Doctortrends.scss";
@import "./Patientexperience.scss";
@import "./Mypatient.scss";
@import "./Doctorregis.scss";
@import "./Medicalrecords.scss";
@import "./QuadrantGrid.scss";