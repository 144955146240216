.car{
    border: 2px solid #199a8e;
    margin: 14px;
    border-radius: 10px;
    box-shadow: 0 4px 6px #199a8e; /* Add box shadow */
    max-width: 467px;
    max-height: 131px;
    &:hover {
        box-shadow: 0 8px 12px #199a8e; /* Adjust box shadow on hover */
      }

}