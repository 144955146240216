.typing-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .typing-text {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid black;
    animation: typing 10s steps(80, end), blink-caret .75s step-end infinite;
    font-size: 0.978rem;
    font-weight: 500;
  }
  
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: black }
  }
  